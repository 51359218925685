import "./Legals.scss";

export const TermOfUsePage = () => {
   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
   return (
      <div className="terms-of-use">
         <h1>TERMS OF USE</h1> <br />
         <br />
         These General Terms of Use (hereinafter the “ Terms ”) apply to any use or
         consultation of the
         <a href="https://www.wolfiesports.com"> https://www.wolfiesports.com </a>
         website (hereinafter the “ Site ”), operated by GAMMANIA WOLF LIMITED, a limited liability company registered under Registration number PVT-V7UAM25Z and having its registered address at Fortis Tower, Westlands, Nairobi, Kenya (hereinafter the "Company").
         The technology used by the website is provided by SAS PL4Y Media, a limited liability company registered with the "Registre du Commerce et des Sociétés" of France.
         The Site is hosted by AMAZON WEB SERVICES EMEA, a limited liability company registered with the “Registre du Commerce et des Sociétés” of Luxembourg under the number 831 001 334, headquarters at 38 Avenue John F. Kennedy L 1855 99137 Luxembourg (hereinafter the “ Host ”).
         <br /> <br />
         <b>ARTICLE 1 : Purpose</b>
         <br />
         <br />
         The Company is an international online platform
         <a href="https://wolfiesports.com"> https://wolfiesports.com</a> dedicated to
         esport players, teams and organizations, and aims to entertain players through video-games and esports activities.
         Players can assess team management tools and participate in tournaments. <br />
         <br />
         These Terms of Use define the conditions under which the user (“ You ”, “ Your ”)
         may use and consult the Site.
         <br />
         <br />
         Consequently, You must accept the Terms of Use by checking the box “I accept the
         Terms of Use” when You register.
         <br />
         <br />
         The Company reserves the right to modify the Terms of Use at any time by publishing a new version on the Site. The Terms of Use thus modified will be applicable as soon as they are put on the Site.
         <br />
         <br /> <br /> <br />
         <b>ARTICLE 2 : Registration on the Site</b> <br />
         <br />
         When You register on the Site, You must enter Your email, Your username
         and Your password. <br />
         <br />
         You are the sole holder of Your account and acknowledge that You are responsible for any problem that may result from it and more generally for any action taken through Your account. The creation of Your account is personal and must not harm a third party.
         <br />
         <br />
         Furthermore, You agree to provide only accurate information, which must not have sexual, racist, or illegal connotations. You also agree to regularly update Your information.<br />
         <br />
         In case of non-compliance with these Terms of Use, the Company reserves the right to suspend or delete Your user account. You will no longer be able to access Your information, Your account, and all rights that You have acquired on the Site will be deleted. <br />
         <br />
         The Company reserves the right to modify these Terms at any time, by publishing a new version on the Website. The applicable Terms are those in effect on the date the order is placed.
         <br />
         <br /> <br />
         <br /> <br />
         <b>ARTICLE 3 : Wolfie Sports Mobile Application </b> <br />
         <br />
         The Wolfie Sport Mobile Application allows You to access the
         functionalities of the Site and much more directly from Your mobile :
         <br />
         <br />• Find tournaments via a calendar
         <br />• Simply register/register
         <br />• Create and manage Your team
         <br />• Take a photo of their results when participating in tournaments and upload them as proof
         <br />• Call an arbiter or send a message to the customer support
         <br />• Receive notifications of matches in progress and upcoming
         <br />
         <br />
         For more information on the treatment of Your data, please find our
         Privacy Policy here :
         <a href="https://wolfiesports.com/privacy">
            https://wolfiesports.com/privacy
         </a>
         . <br />
         <br /> <br />
         <b>ARTICLE 4 : Participation in tournaments</b> <br />
         <br />
         4.1. Participation of minors in tournaments
         <br />
         <br /> <i>4.1.1. Participation of minors under 12 years old</i> <br />
         <br />
         In accordance with Kenya Children’s Act (2012) and Article R321-44 of the « Code de la sécurité intérieure
         », the participation of children under 12 years old in esport tournaments offering monetary rewards is prohibited. <br />
         <br />
         For tournaments that do not offer monetary rewards, parental authorization is required. This authorization must be accompanied by a copy of the passport or Identity card of the legal representative and the child’s passport or Identity card <br /> <br />
         <i> 4.1.2. Participation of minors over 12 years old </i>
         <br />
         <br />
         The participation of minors over 12 years old in esport tournaments
         offering monetary reward is subject to parental authorization in
         accordance with Kenya Children’s Act (2012) and Article R321-43 of the “Code de la sécurité intérieure”.
         This parental authorization must be accompanied by valid documents proving the identity of the legal representative and that of the minor.
         <br />
         <br />
         4.2. Prizes
         <br />
         <br />
         <i> 4.2.1. Monetary rewards</i>
         <br />
         <br />
         The Company reserves the right to modify the amount or nature of the reward at any time and without prior information.
         <br />
         <br />
         If You are the winner of a tournament or one of the winners according to the terms and conditions of the tournament, You must claim Your award(s).
         <br />
         <br />
         To claim your reward, you will either:
         <br />• Receive an email at the address provided during registration, requesting the necessary documents (such as bank details, valid proof of identity, etc.), or
         <br />• Be able to claim it directly from the wallet section on the website.
         <br />
         Additionally, you must have an M-Pesa Mobile Money account linked to the mobile number used for registration or in your personal settings, as this will be the primary method for KSh payouts.<br />
         <br />
         Once you submit the requested documents and they are approved, you will receive your reward within 90 working days.<br />
         <br />
         Be careful: If You do not claim Your reward within 90 days following the end of the tournament, You will not be able to recover it. After this period, the reward is cancelled. <br />
         <br /> <i> 4.2.2. Material rewards</i> <br />
         <br />
         The Company reserves the right to modify the nature of the reward at any time and without prior information.
         <br />
         <br />
         If You are the winner of a tournament or one of the winners, You must claim Your reward. <br />
         <br />
         To claim the reward, You will receive an email to the address You provided during registration, in which we will request the documents needed to claim Your reward: valid proof of identity, delivery address, telephone number, and size (if the reward is clothing), etc.
         <br />
         Once you submit the requested documents and they are approved, you will receive your reward within 90 working days. <br />
         <br />
         Be careful: If You do not claim Your reward within 90 days following the end of the tournament, You will not be able to recover it. After this period, the reward is cancelled.
         <br />
         <br />
         4.3. Penalties
         <br />
         <br />
         <i>4.3.1. Cheat</i> <br />
         <br />
         Any participation in the tournament is subject to the respect of the rules
         and Your loyalty in the game. It is strictly prohibited to modify or
         attempt to modify the features of the proposed game, in particular to
         modify the results. <br />
         <br />
         The use of any third-party programs is also prohibited (e.g., cheating, scripting). Additionally, the use of spam is prohibited. If You do not respect these rules, the Company reserves the right to exclude You from the tournament and the Site. You will also be "blacklisted" by the Company and will no longer be able to participate in its tournaments or benefit from other advantages of the Site.
         <br />
         <br /> <i>4.3.2. Language abuse and inappropriate behavior </i> <br />
         <br />
         Participation in tournaments is conditioned on Your commitment to behave respectfully towards the Company, tournament administrators, players, partners, and others. You agree not to infringe upon the rights of any third party by behaving in a rude, abusive, or inappropriate manner, or by making statements contrary to the principles of the Company. <br />
         <br />
         Failure to respect these rules will result in immediate sanctions by the Company. The Company reserves the right to exclude You from all tournaments and from the Site. <br />
         <br /> <br />
         <b>ARTICLE 5 : Acceptance of risk</b> <br />
         <br />
         You understand and agree that there are risks associated with esports, including but not limited to, eye damage, carpal tunnel syndrome, repetitive stress, and other damages associated with long periods of computer and console gaming. You hereby expressly assume all such risks.
         <br />
         <br /> <br />
         <b>ARTICLE 6 : PEGI</b> <br />
         <br />
         The Pan European Game Information (PEGI) is an European rating system for
         video games, which classifies video games according to age and aims to
         help consumer, especially the parents, to inform themselves about each
         types of video games using different logo, which You can find at.

         <a href="https://pegi.info/fr."> https://pegi.info/fr.</a> <br />
         <br />
         The PEGI of the games is also specified on the parental authorization
         intended for parents and/or legal representatives of minors over 12 years
         old who want to participate in esport tournament on the Site. <br />
         <br /> <br />
         <b>ARTICLE 7 : Intellectual property</b> <br />
         <br />
         The use of the Site doesn’t give You any right on the elements on the Site
         and in particular the texts, photos, videos, data, posters, logos,
         trademarks and other elements reproduced on the Site. These elements are
         reserved and protected by the intellectual property law and remain the
         exclusive property of the Company, and, where applicable, of third parties
         who have authorized it to reproduce them on the Site. <br />
         <br />
         As such and in accordance with the Intellectual Property Code, only use
         for private use within a family circle is authorized. Any other use
         constitutes counterfeiting and infringement of neighboring rights,
         punishable by the Intellectual Property Code. <br />
         <br />
         Consequently, You may not under circumstances, represent, distribute,
         market, modify or concede all or part of any of the elements reproduced on
         the Site and all or part of the Site in general, without the prior and
         express consent of the Company. <br />
         <br />
         Any illegal use of all or part of the Site (piracy, counterfeiting, etc)
         may give rise to legal proceedings. <br />
         <br /> <br />
         <b>ARTICLE 8 – PERSONAL DATA</b> <br />
         <br />
         You and the Company acknowledge your awareness of and compliance with all applicable data protection regulations, including European and national legislative or regulatory provisions, particularly Regulation (EU) 2016/679 of April 27, 2016 (GDPR), on the protection of individuals regarding the processing of personal data, as well as any future amendments or related regulations, and the data protection regulations applicable in Kenya, including the Data Protection Act, 2019 (DPA), and any future amendments or related regulations. Both parties recognize their obligations to adhere to these regulations and any future provisions that may supplement or replace them.
         <br />
         <br />
         The Company collects and processes only the personal data strictly necessary for the operation of its services, in accordance with the principles of lawfulness, fairness, transparency, and data minimization as required under the DPA.<br />
         <br />
         The following personal data may be collected:<br />
         <br />• First name and surname
         <br />• Email address
         <br />• Address
         <br />• Username
         <br />• Game history
         <br />• Conversation history on the Site
         <br />• Country of origin
         <br />
         <br />
         This data will be retained for the duration necessary to fulfill service obligations and will be deleted upon the closure of your account unless required for legal or regulatory compliance. <br />
         <br />
         In addition, You have the right to access, rectify and delete personal
         data concerning You. You can contact the customer service via the forms
         available on the website
         <a href="https://www.wolfiesports.com"> https://www.wolfiesports.com</a> <br />
         <br />
         You can reach us using the different methods available on the Assistance/Contact Us page of the Site, either by email or by opening a ticket on our Discord server, which you can also find on the contact page.
         <br />
         <br />
         Additionally, each form includes specific notices to ensure you are fully informed before submitting your data. <br />
         <br />
         For more information about the process of Your data, please find the following link :
         <a href="https://wolfiesports.com/privacy">
            https://wolfiesports.com/privacy
         </a>
         .<br />
         <br /> <br />
         <b>ARTICLE 9 : Cookies</b> <br />
         <br />
         When You register or log in to the Site, a notification will appear requesting Your consent to the use of cookies during Your navigation. <br />
         <br />
         If You accept, cookies embedded in the pages and content You view will be temporarily stored on Your device and will be accessible only to their issuer. <br />
         <br />
         If You refuse or delete cookies, some functionalities essential for navigating certain sections of the Site may become unavailable. In such cases, the Company assumes no responsibility for any resulting limitations or degraded performance of the Site. <br />
         <br />
         Cookie settings vary depending on Your browser. You can manage Your cookie preferences through Your browser’s help menu, which provides instructions on how to modify or disable cookies. <br />
         <br /> <br />
         <b>ARTICLE 10 : Hypertext links</b> <br />
         <br />
         The Site may contain hyperlinks leading to third-party websites.
         <br />
         <br />
         The Company is not responsible, directly or indirectly, for any legal or regulatory violations under Kenyan, French, or European laws that may occur on these third-party websites, nor for any consequences arising from such violations.
         As these external sites are not managed by the Company, it does not control or contribute to their development, content, or updates. The Company disclaims all liability regarding the content, security, or functionality of third-party websites accessed via hyperlinks from the Site.
         <br />
         <br /> <br />
         <b> ARTICLE 11 : Warranty</b>
         <br />
         <br />
         To the fullest extent permitted by law, You expressly acknowledge and agree that the Site is provided "as is" and "as available," with no express or implied warranties from the Company. Your use of the Site is at Your sole risk and responsibility.<br />
         <br />
         The Company doesn’t guarantee that the Site, the contents and products
         offered, will meet Your expectations. The Company also doesn’t guarantee
         that the Site will not be interrupted and free of all errors, that these
         interruptions or errors will be corrected and that it will not contain any
         virus.
         <br />
         <br />
         Any material downloaded or obtained through the Site is at Your own risk. The Company is not liable for any data loss, device damage, or other technical issues resulting from Your use of the Site. <br />
         <br />
         The Company reserves the right to modify, suspend, delete, or restrict access to all or part of the Site at any time without prior notice. The Company shall not be held liable for any resulting inconvenience or loss. <br />
         <br />
         By using the Site, You warrant to the Company that Your use of the Site is legal and
         authorized in all states where You may be required to connect. <br />
         In addition, You are informed that the Site and the content it offers
         cannot be translated into all the languages existing to date and isn’t
         available in all countries of the world. The Company isn’t responsible for
         the inaccuracy of the information displayed on the Site. <br />
         Finally, no advice or information, whether written or oral, that You may
         have obtained from the Company or during the use of the Site, may be
         likely to characterize any warranty not expressly provide for in the Terms of Use.
         <br />
         IN EVERY EXTEND PERMITTED BY LAW, YOU RELEASE THE COMPANY FROM ANY AND ALL
         CLAIMS OR DAMAGES OF ANY KIND, KNOWN OR UNKNOWN, DISCLOSED OR UNDISCLOSED,
         ARISING OUT OF YOUR PARTICIPATION IN ANY EVENT, TOURNAMENT, USE OF GUIDES
         OR TRAINING TO IMPROVE YOUR PERFORMANCE. YOU AGREE THAT THIS LIABILITY
         SHALL THIS BINDING UPON YOU AND YOUR INHERITORS, EXECUTORS, CONTRACTORS
         AND ASSIGNS. <br />
         <br /> <br />
         <b>Article 12 : Liability and force majeure</b> <br />
         <br />
         Subject to applicable laws and regulations, the Company shall not be
         liable for any damage or loss of any kind (including, but not limited to,
         direct, indirect, economic loss or damage) that is directly or indirectly
         related to : <br />
         <br />• The use or inability to use the Site, including but not limited to
         loss of customers, work interruption, breakdown or malfunction of computer
         equipment ; <br />• To the loss or deterioration of information related to
         the user account, statistics, information, lack of stock ; <br />• The
         interruption of services, including but not limited to operating system
         problems, hardware failure, billing system failure, or any other event
         that may result in the loss or interruption of services on the Site ;
         <br />• To the content of the Site ; <br />• Your use of the Site ; <br />
         • To all measures taken by competent persons, internal or external to the
         Company, in consideration of Your use of the information or the sue made
         by another person ; <br />• Any measures relating to copyright and
         intellectual property rights ; <br />• Any error or omission in the
         operation of the services of the Site ; <br />• Any damage caused to the
         computer hardware or other equipment, including but not limited, to any
         damage resulting from violation of a security standard, viruses, bugs,
         fraud, error, omission, interruption, network failure, delay in operation,
         even if it could be within the framework of contractual relations or if it
         results from contractual responsibility (including but not limited to
         damage cause by force majeure, theft or destruction of property). <br />
         <br />
         Finally, the Company shall not be liable when the amount of compensation
         for the damage suffered is greater than the amount paid by You during the
         12 months preceding the damage.
         <br />
         <br /> <br />
         <b>ARTICLE 13 : Closure or suspension of the Site</b> <br />
         <br />
         The Company reserves the right, when it wishes, to modify, interrupt
         temporarily or permanently, all or part of the Site, without notice or
         compensation of any kind. <br />
         <br />
         Also, the Company may not be liable to You or to any third party for any
         modification, interruption or suspension of the Site. The version of Terms of Use
         that You must respect, and which will be opposed to You in the event of a
         dispute is the one in force on the Site. <br />
         <br /> <br />
         <b> ARTICLE 14 : Rules of conduct</b>
         <br />
         <br />
         By registering on the Site You agree to respect the following rules :
         <br />
         <br />• You must not participate in any activity who violates the laws and
         regulations in force ; <br />• You must not use the services to which You
         have access to develop, host or distribute cheating elements, robotic
         software, hacks, or any other unauthorized software ; <br />• You may not
         copy, alter or modify the purpose of the source code and content available
         on the Site, including without limitation for commercial use, including
         without limitation : use in cybercafé, data center, or other
         location-based site, without the express consent of the Company (a); the
         collection of money, content or resources from the Site for sale to third
         parties (b); sale of accounts, login information or other confidential
         information (c) ; <br />• You may not disable, hack into or attempt to
         interfere in any way with the security of the Site or anything else that
         would restrict the use of copying of any content of the Site or of
         material owned by the Company and for which the Company hasn’t given its
         consent ; <br />• You agree not to use any third-party software not
         authorized by the Company that intercepts or collects information from or
         through the Site ; <br />• You shall not disrupt the servers or networks
         of the Site, including, but not limited to, by transmitting viruses,
         spyware, malware or any other code of a destructive nature. You are not
         authorized to introduce any content or code, or interfere in any way with
         the Site ; <br />• You mustn’t, unless expressly authorized in writing by
         the Company, host, provide or develop content that interferes with the
         Site, in particular to play on the internet or on a network ; <br />• You
         mustn’t destroy, interfere with or disrupt (including scanning, probing or
         testing for vulnerabilities) any web pages available on the Site, servers
         or networks connected to the Site ; <br />• You may not solicit, collect
         or use the login information or personal data of other users ; <br />• You
         shall not defame, stalk, attempt to intimidate, threaten or impersonate
         any third party and You shall not publicly disclose on the Site any
         personal information about You or any other information about another user
         (bank details, social security number, private telephone number, private
         email, etc) ; <br />• You mustn’t engage in degrading behavior to another
         person or group because of their age, religion, sex, sexual orientation,
         race, ethnic origin or disability ;<br />
         • You mustn’t use the content of the Site for any purpose that is unlawful
         or not provided for by law or the Company ;<br />
         • You may not create or submit any letter, email, comments or any other
         form of commercial communications not consented to by other users ;<br />
         • You may not create accounts in any inappropriate manner, including but
         not limited to, by using an automated device, script, bot, etc ;
         <br />• You may not create accounts in any other manner that isn’t
         authorized by the Company ; <br />• You shall not prevent any user from
         accessing the Site and You shall not encourage the violation of the Terms of Use or
         any other terms and conditions of the Company ; <br />• You agree not to
         infringe any patent, trademark, trade secret, copyright, right of
         publicity or any other right of any third party or other entity ; <br />•
         You agree not promote, in any way whatsoever, a brand competitor to the
         Company.
         <br />
         <br /> <br />
         <b>ARTICLE 15 : Moderation of opinions and comments</b> <br />
         <br />
         The Company makes available to You, on the Site, a chat allowing You to
         communicate with the other users (hereinafter the “Chat”). If You wish to
         post messages on the Chat, You must do so in accordance with the Terms of Use.
         Therefore, You agree to :<br />
         <br />• Use the Chat in accordance with the laws and regulations in force,
         and in no way that is illegal ;
         <br />
         <br />• Not to publish or create a hypertext link that : <br />
         o Contains viruses, files or code designed to interrupt, destroy or limit
         the Site, collect or store personal information about other users
         <br />
         o Damages the identity of a third party, another company, etc o Is
         defamatory, offensive, provocative, hateful, threatening or likely to
         cause nuisance and disturbance
         <br />
         o Infringe or violate the rights of any person, company, corporation,
         including but not limited to, the right to privacy, intellectual property,
         contractual and confidentiality rights
         <br />
         o Constitutes discrimination on the basis of race, sex, religion,
         nationality, disability, sexual orientation or age
         <br />o Contains advertising or any form of undesired promotion such as
         spam <br />
         <br />• Don’t use the Chat to interrupt or prevent the good functioning of
         the Site, alter his its content or make it ineffective ; <br />• Don’t
         attempt to access the Site or its contents in unauthorized manner. <br />
         <br />
         The Chat isn’t the responsibility of the Company, because the Company just
         make a means of communication at Your disposition. Therefore, the messages
         published on the Chat don’t reflect the opinion of the Company and the
         sole responsibility of their author. In addition, the Site benefits from a
         moderation system for messages published on the Chat. So, the Company
         reserves the right to ban any user who doesn’t not comply with these
         rules/. You are responsible for the messages that You publish on the Site,
         You agree to indemnify the Company for any claims (ex: legal fees) or
         damages resulting from the publication of Your messages. <br />
         <br />
         Finally, the Company cannot guarantee the veracity and accuracy of the
         information given on the Chat, which isn’t its responsibility. In the
         event that You wish to exercise a right, make a claim or take action
         against a third party, You will do so independently and without against
         the Company.
         <br />
         <br /> <br />
         <b>ARTICLE 16 : Duration</b> <br />
         <br />
         These Terms of Use are applicable indefinitely. You agree to comply with them from the moment You accept them by ticking the required checkbox and for as long as You use or access the Site. <br />
         <br /> <br />
         <b>ARTICLE 17 : Customer service</b> <br />
         <br />
         The Company’s customer service department is available from 11:00 AM to
         7:00 PM (EAT) for all Your questions and requests for information. You can
         contact them via the forms available on the website
         <a href="https://www.wolfiesports.com">
            https://www.wolfiesports.com
         </a> : <br />
         <br /> <br />
         <b>ARTICLE 18 : Applicable law and settlement of disputes</b> <br />
         <br />
         These Terms of Use are governed by and interpreted in accordance with the laws of Kenya.
         <br /><br />
         <b>ARTICLE 19 : State legislation</b>
         <br />
         <br />
         You are subject to the laws and regulations in force in the countries from
         which You connect to the Site and where You use the content of the Site.
         You are solely responsible for complying these laws and regulations in
         force in these territories. <br />
         <br />
         Consequently, You agree that the Company cannot be liable for the laws to
         which You are subject in these territories, which restrict or prohibit You
         registration on the Site or participation in the tournaments. <br />
         <br />
         Also, the Company doesn’t guarantee that the content of the Site, the
         services offered, or the information displayed are appropriate or
         available for use outside the Kenya territory. It’s strictly forbidden to
         access the Site from territories where its use is illegal. Finally, if You
         access the Site from outside Kenya, You do so at Your own risk. You are
         solely responsible for compliance with local laws. <br />
         <br />
         The Company reserves the right to monitor the place from which You connect
         to the Site to block this access whenever it’s illegal or limited.
         <br />
         <br /> <br />

         <br />
      </div>
   );
};
