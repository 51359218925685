import { Button, ButtonType } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { updateLocalStorageObject } from "../../../services/Auth";
import { patchAvatar, patchBanner } from "../../../services/no-user";
import "../OnBoarding.scss";

const profiles = Array.from(
  { length: 5 },
  (_, i) =>
    `https://assets.next.wolfiesports.com/media/assets/user/profile/${i}.png`
);

const banners = Array.from(
  { length: 5 },
  (_, i) =>
    `https://assets.next.wolfiesports.com/media/assets/user/banner/${i}.png`
);

type OnBoardingUserSettingsProps = {
  nextStep: () => void;
  prevStep: () => void;
  setStep: (step: number) => void;
  userGameAccounts: Record<string, string>;
};
export const OnBoardingUserSettings = ({
  nextStep,
  prevStep,
  setStep,
  userGameAccounts,
}: OnBoardingUserSettingsProps) => {
  const storedUser = JSON.parse(localStorage.getItem("user") ?? "");

  const [selectedProfile, setSelectedProfile] = useState("0");
  const [selectedBanner, setSelectedBanner] = useState("0");

  useEffect(() => {
    updateBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBanner]);

  useEffect(() => {
    updateAvatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfile]);

  const handleSelectProfile = (profileSelected: string) => () => {
    setSelectedProfile(profileSelected);
  };

  const handleBannerProfile = (bannerSelected: string) => () => {
    setSelectedBanner(bannerSelected);
  };

  const updateAvatar = async () => {
    if (selectedProfile) {
      await patchAvatar(
        storedUser.identity.id,
        selectedProfile.toString()
      ).then(() => {
        updateLocalStorageObject("user", "settings.avatar", selectedProfile);
      });
    }
  };

  const updateBanner = async () => {
    if (selectedBanner) {
      await patchBanner(storedUser.identity.id, selectedBanner.toString()).then(
        () => {
          updateLocalStorageObject("user", "settings.banner", selectedBanner);
        }
      );
    }
  };

  const handlePrevStep = () => {
    //console.log(userGameAccounts);
    if (Object.keys(userGameAccounts).length > 0) {
      prevStep();
    } else {
      setStep(0);
    }
  };

  const isMobile = window.innerWidth < 1000;
  return (
    <div className="game-account-container">
      <h5>
        <FormattedMessage
          id={"webapp.onboarding.user-settings.setup-your-settings"}
        />
      </h5>
      <div className="account-settings-content">
        {isMobile && (
          <>
            <Button
              setClick={handlePrevStep}
              label={<FormattedMessage id={"webapp.onboarding.back-button"} />}
              type={ButtonType.TEXT}
            />
            <Button
              setClick={nextStep}
              label={<FormattedMessage id={"webapp.onboarding.save-changes"} />}
            />
          </>
        )}
        <div className="title">
          <h5>
            <FormattedMessage id={"webapp.settings.media.profile-picture"} />
          </h5>
          <span>
            <FormattedMessage
              id={"webapp.settings.media.select-your-profile-picture"}
            />
          </span>
        </div>
        <div className="content">
          <div className="profile-selection">
            {profiles.map((imgSrc, index) => (
              <div
                key={imgSrc}
                className={`profile-item ${
                  selectedProfile === index.toString() ? "active" : ""
                }`}
                onClick={handleSelectProfile(index.toString())}
              >
                <img
                  src={imgSrc}
                  alt={`Profile ${index}`}
                  className="profile-card"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="title">
          <h5>
            <FormattedMessage id={"webapp.settings.media.banner"} />
          </h5>
          <span>
            <FormattedMessage
              id={"webapp.settings.media.select-your-profile-banner"}
            />
          </span>
        </div>
        <div className="content">
          <div className="banner-selection">
            {banners.map((imgSrc, index) => (
              <div
                key={imgSrc}
                className={`banner-item ${
                  selectedBanner === index.toString() ? "active" : ""
                }`}
                onClick={handleBannerProfile(index.toString())}
              >
                <img
                  src={imgSrc}
                  alt={`Banner ${index}`}
                  className="banner-card"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {!isMobile && (
        <>
          <Button
            setClick={handlePrevStep}
            label={<FormattedMessage id={"webapp.onboarding.back-button"} />}
            type={ButtonType.TEXT}
          />
          <Button
            setClick={nextStep}
            label={<FormattedMessage id={"webapp.onboarding.save-changes"} />}
          />
        </>
      )}
    </div>
  );
};
