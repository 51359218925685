import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import "./Footer.scss";

const facebookImg = require("../../assets/Socials/facebook.png");
const instagramImg = require("../../assets/Socials/instagram.png");
const twitchImg = require("../../assets/Socials/twitch.png");
const twitterImg = require("../../assets/Socials/twitter.png");

export const Footer = () => {
  const navigate = useNavigate();

  const handleClickLink = (social: string) => () => {
    window.open(social, "_blank");
  };

  const handleClickNavigate = (nav: string) => () => {
    navigate(nav);
  };

  return (
    <div className="footer-container">
      <div className="left-content">
        <img
          src={facebookImg}
          alt="facebook-img"
          onClick={handleClickLink(
            "https://web.facebook.com/Wolfie-Sport-100791248418128"
          )}
        />
        <img
          src={instagramImg}
          alt="instagram-img"
          onClick={handleClickLink("https://www.instagram.com/wolfie_sports/")}
        />
        <img
          src={twitchImg}
          alt="twitch-img"
          onClick={handleClickLink("https://www.twitch.tv/wolfiesport/")}
        />
        <img
          src={twitterImg}
          alt="twitter-img"
          onClick={handleClickLink("https://twitter.com/WolfieSports")}
        />
      </div>
      <div className="right-content">
        <span className="underline" onClick={handleClickNavigate("/")}>
          © Wolfiesports 2024
        </span>
        <span>•</span>
        <span
          className="underline"
          onClick={handleClickNavigate("/terms-of-use")}
        >
          <FormattedMessage id={"webapp.footer.term-of-use"} />
        </span>
        <span>•</span>
        <span
          className="underline"
          onClick={handleClickNavigate("/terms-of-sales")}
        >
          <FormattedMessage id={"webapp.footer.term-of-sale"} />
        </span>
        <span>•</span>
        <span className="underline" onClick={handleClickNavigate("/privacy")}>
          <FormattedMessage id={"webapp.footer.privacy-and-cookies-policy"} />
        </span>
      </div>
    </div>
  );
};
