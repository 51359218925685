import { Button, ButtonType, GameAccount, getGameAccountByGame, NCInput } from "@wolfiesports/srm-component";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  GameAccountModel,
  UserGameAccountModel,
} from "../../../models/GameModel";
import { getUserFavoriteGames } from "../../../services/Auth";
import {
  getPublicGameAccounts,
  getUserGameAccounts,
  putUserGameAccount,
} from "../../../services/no-games";

type OnBoardingGameAccountsProps = {
  nextStep: () => void;
  prevStep: () => void;
  handleSetUserGameAccounts: (accounts: Record<string, string>) => void;
};
export const OnBoardingGameAccounts = ({
  nextStep,
  prevStep,
  handleSetUserGameAccounts,
}: OnBoardingGameAccountsProps) => {
  const [publicGameAccounts, setPublicGameAccounts] = useState<
    GameAccountModel[]
  >([]);
  const [userGameAccounts, setUserGameAccounts] = useState<
    Record<string, string>
  >({});
  const [userFavoriteGames, setUserFavoriteGames] = useState<
    GameAccountModel[]
  >([]);
  const user = JSON.parse(localStorage.getItem("user") ?? "");
  const userId = user.identity.id;

  const fetchUserGameAccounts = async () => {
    try {
      const res = await getUserGameAccounts();
      const accounts: Record<string, string> = {};
      res.list.forEach((account: UserGameAccountModel) => {
        accounts[account.id] = account.gamerTag;
      });
      setUserGameAccounts(accounts);
      //console.log(res.list);
    } catch (error) {
      console.error("Error fetching user game accounts", error);
    }
  };

  const fetchPublicGameAccounts = async () => {
    try {
      const res = await getPublicGameAccounts();
      setPublicGameAccounts(res.list);
      //console.log(res.list);
    } catch (error) {
      console.error("Error fetching public game accounts", error);
    }
  };

  const fetchUserFavoriteGames = async () => {
    try {
      const res = await getUserFavoriteGames(userId);
      setUserFavoriteGames(res);
    } catch (error) {
      console.error("Error fetching user favorite games", error);
    }
  };

  const handleInputChange = (id: string, value: string) => {
    setUserGameAccounts((prev) => ({ ...prev, [id]: value }));
  };

  const sendUserGameAccount = async (id: string, gamerTag: string) => {
    try {
      await putUserGameAccount(id, gamerTag);
    } catch (error) {
      console.error("Error updating game account", error);
    }
  };

  const saveChanges = () => {
    Object.entries(userGameAccounts).forEach(([id, gamerTag]) => {
      sendUserGameAccount(id, gamerTag);
    });

    handleSetUserGameAccounts(userGameAccounts);

    nextStep();
  };

  useEffect(() => {
    fetchUserFavoriteGames();
    if (!publicGameAccounts.length) {
      fetchPublicGameAccounts();
    }
    if (!Object.keys(userGameAccounts).length) {
      fetchUserGameAccounts();
    }
  }, []);

  const [filteredGames, setFilteredGames] = useState<GameAccount[]>([]);

  const fetchGameAccounts = async (favoriteGames: GameAccountModel[]) => {
    const filteredGames: GameAccount[] = [];
  
    try {
      const gameAccountPromises = favoriteGames.map(async (game) => {
        const gameAccounts = await getGameAccountByGame(game.id);

        //console.log("Got Gameaccounts,", gameAccounts)
  
        if (gameAccounts && gameAccounts.list) {
          return gameAccounts.list; 
        }
        return []; 
      });
  
      const results = await Promise.all(gameAccountPromises);
  
      results.forEach(gameAccounts => {
        filteredGames.push(...gameAccounts);
      });
  
      if (filteredGames.length === 0) {
        nextStep();
      } else {
        setFilteredGames(filteredGames);
      }
    } catch (error) {
      console.error("Error fetching game accounts:", error);
    }
  };

  useEffect(() => {
    if (userFavoriteGames.length > 0) {
      fetchGameAccounts(userFavoriteGames);
    }
  }, [publicGameAccounts, userFavoriteGames]);

  const isMobile = window.innerWidth < 1000;

  return (
    <div className="game-account-container">
      <h5>
        <FormattedMessage
          id={"webapp.onboarding.game-account.enter-your-games-accounts"}
        />
      </h5>
      <div className="forms-container">
        {isMobile && (
          <div className="button-container">
            <Button
              setClick={prevStep}
              label={<FormattedMessage id={"webapp.onboarding.back-button"} />}
              type={ButtonType.TEXT}
            />
            <Button
              setClick={saveChanges}
              label={<FormattedMessage id={"webapp.onboarding.save-changes"} />}
            />
          </div>
        )}
        {filteredGames.map((account) => (
          <div className="form-item" key={account.id}>
            <div
              className="game-logo"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_AWS_S3_BUCKET}/media/games/${account.id}/LogoImage)`,
              }}
            />
            <NCInput
              label={account.id}
              value={userGameAccounts[account.account] || ""}
              onChange={(value: string) => handleInputChange(account.account, value)}
            />
          </div>
        ))}
      </div>
      {!isMobile && (
        <div className="button-container">
          <Button
            setClick={prevStep}
            label={<FormattedMessage id={"webapp.onboarding.back-button"} />}
            type={ButtonType.TEXT}
          />
          <Button
            setClick={saveChanges}
            label={<FormattedMessage id={"webapp.onboarding.save-changes"} />}
          />
        </div>
      )}
    </div>
  );
};
