import { ReactSRMWrapper } from "@cactus/react-srm-wrapper";
import { useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../store";
import { useLogout, useSession } from "../../utils/session/session";
import { EventSRMType } from "@wolfiesports/srm-component";
import { EventSubscribe, useWebSocket } from "../../utils/websocket/WebSocketProvider";
import { useEffect, useState } from "react";

export const WebappMenu: React.FC = () => {
  const originUrl = process.env.REACT_APP_BASE_BUNDLE_URL + "webapp-menu";
  const navigate = useNavigate();
  const { subscribe } = useWebSocket();
  const [event, setEvent] = useState<any>(null);

  const logoutCognito = useLogout();
  const { data: user } = useSession({ enabled: true });
  const localUser = useStoreState((s) => s.login.user);
  const setIsLoggedIn = useStoreActions((s) => s.login.setIsLoggedIn);
  const setUser = useStoreActions((s) => s.login.setUser);
  const language = localStorage.getItem("i18nextLng") ?? "en";

  useEffect(() => {
    setEvent({ type: "ws", data: localUser });
  }, [localUser]);

  const handleLogin = async () => {
    navigate("/login");
  };

  const handleRegister = async () => {
    navigate("/register");
  };

  const handleLogout = async () => {
    if (user) {
      logoutCognito.mutate(user, {
        onSuccess: () => {
          localStorage.removeItem("user");
          localStorage.removeItem("accessToken");
          setUser(null);
          setIsLoggedIn(false);
          window.location.href = "/home";
        },
      });
    }
  };

  const handleNavigate = async (path: string) => {
    navigate('/');
    // After a slight delay, navigate back to the same page
    setTimeout(() => {
      navigate(path);
    }, 0);
  };

  const handleChangeLang = async (language: string) => {
    localStorage.setItem("i18nextLng", language);
    window.location.reload();
  };

  const handleSubscribe = async (data?: EventSubscribe) => {
    //console.log('WebApp Subscribed :', data);
    if (data?.rt) {
      subscribe("srm", data.id, (room, message) => {
        if (!message || !room) {
          return;
        }
        sendSrmEvent(message);
      });
    }
    return;
  }

  const sendSrmEvent = async (data?: any): Promise<any> => {
    //console.log('WebApp Menu : sendSrmEvent', data);
    setEvent({ type: "ws", data: data });
  };

  const eventHandlers = {
    login: handleLogin,
    register: handleRegister,
    logout: handleLogout,
    [EventSRMType.NAVIGATE_BY_URL]: handleNavigate,
    changeLanguage: handleChangeLang,
    [EventSRMType.WS_SUBSCRIBE]: handleSubscribe,
  };

  return (
    <ReactSRMWrapper
      originUrl={originUrl}
      exportPath={"nicecactus.menu"}
      nav={navigate}
      language={language}
      eventHandlers={eventHandlers}
      event={event}
    />
  );
};
